import * as React from "react";
import { useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
// import { AnchorLink } from "gatsby-plugin-anchor-links";
import classNames from "classnames";
import { SlideDown } from "react-slidedown";
import { StaticImage } from "gatsby-plugin-image";
import Page from "../components/Layout/Page";
import Container from "../components/Layout/Container";
import { CaretDown } from "../images/svgs";
import Seo from "../components/reusable/Seo";

import BtnFilled from "../components/reusable/BtnFilled";

import "react-slidedown/lib/slidedown.css";

const CareerPage = () => {
  const breakpoints = useBreakpoint();
  const [experience, toggleExperience] = useState("senior");

  const FresherContent = (
    <>
      <div className="text fresher">
        <div>
          <p className="intro">
            We are a growth-stage startup driven by innovation.
          </p>
          <p>
            With Inviz, you can get a head start in your career working with the
            latest technologies in Artificial Intelligence and Cloud Computing.
            You will work on core projects, and you will learn by solving real
            business problems.
          </p>
          <p>
            You will learn across technologies and across computing languages,
            and specialize in areas of your choice. Our training programs give
            you ample learning opportunities, and hands-on experience to
            accelerate your career roadmap.
          </p>
        </div>
        <figure>
          <StaticImage
            src="./../images/careers/freshers.jpg"
            alt="Careers at Inviz AI - Hiring"
            layout="fullWidth"
            loading="lazy"
            placeholder="blurred"
          />
        </figure>
        <div>
          <p>
            Our flat-hierarchy and inclusive culture provides exciting and
            empowering opportunities for you to make your voices heard.
          </p>
          <p>
            We give you a platform to share your thought process no matter how
            early in your career you are!
          </p>
        </div>
      </div>
    </>
  );

  const SeniorContent = (
    <>
      <div className="text senior">
        <div>
          <p>
            At Inviz, we are always looking for seasoned professionals who can
            take up challenging roles. You will work on critical projects and
            solve core business problems with us. The projects you handle will
            challenge you and enhance your expertise in the core AI and ML
            technologies.
          </p>
        </div>
        <figure>
          <StaticImage
            src="./../images/careers/senior.jpg"
            alt="Careers at Inviz AI - Hiring"
            layout="fullWidth"
            loading="lazy"
            placeholder="blurred"
          />
        </figure>
        <div>
          <p className="intro">You will grow in the organization.</p>
          <p>
            We work on mission critical projects for our clients. The projects
            you handle will challenge you. Our flat-hierarchy ensures you get
            high visibility.
          </p>
          <p>
            You will closely work with the leaders of the organization and share
            their vision.
          </p>
        </div>
      </div>
    </>
  );

  return (
    <Page className="careers">
      <Seo
        title="We are Hiring : Careers at Inviz AI"
        path="careers"
        description="Do great work and grow at Inviz AI. We are looking to grow our team specializing in AI and ML, and we need you at Inviz.
        Together, we can leverage technology and solve crucial business
        problems."
      />
      <section className="hero">
        <Container>
          <div className="text">
            <div className="h1-prefix">
              <b>Join Our Team</b>
            </div>
            <h1>
              <b>
                Do great work
                <br />
                &amp; grow at Inviz
              </b>
            </h1>
            {breakpoints.sm && (
              <div className="mobile-images">
                <div className="imageOne">
                  <StaticImage
                    src="./../images/careers/careers-at-inviz-01.jpg"
                    alt="Careers at Inviz Ai"
                    layout="fullWidth"
                    loading="eager"
                    placeholder="blurred"
                  />
                </div>
              </div>
            )}
            <div className="cta">
              <BtnFilled
                to="https://careers.inviz.ai/jobs/Careers"
                color="blue"
                context="external"
              >
                See Open Positions
              </BtnFilled>
            </div>
          </div>
          {!breakpoints.sm && (
            <div
              className={classNames(
                "desktop-images",
                !breakpoints.lg && "wide"
              )}
            >
              {!breakpoints.lg && (
                <div className="imageThree">
                  <StaticImage
                    src="./../images/careers/careers-at-inviz-03.jpg"
                    alt="Careers at Inviz Ai"
                    layout="fullWidth"
                    loading="eager"
                    placeholder="blurred"
                  />
                </div>
              )}
              <div className="imageOneTwo">
                <div className="imageOne">
                  <StaticImage
                    src="./../images/careers/careers-at-inviz-01.jpg"
                    alt="Careers at Inviz Ai"
                    layout="fullWidth"
                    loading="eager"
                    placeholder="blurred"
                  />
                </div>
                {!breakpoints.lg && (
                  <div className="imageTwo">
                    <StaticImage
                      src="./../images/careers/careers-at-inviz-02.jpg"
                      alt="Careers at Inviz Ai"
                      layout="fullWidth"
                      loading="eager"
                      placeholder="blurred"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </Container>
      </section>
      <section className="what-we-do">
        <Container>
          <h2>
            <b>What we do</b>
          </h2>
          <div>
            <p>
              We solve core business problems with Artificial Intelligence and
              Machine Learning. Our solutions enable companies to undergo
              digital transformation across verticals, around the globe.
            </p>
          </div>
        </Container>
      </section>
      <section className="hiring">
        <Container>
          {!breakpoints.md && (
            <figure className="desktop">
              <StaticImage
                src="./../images/careers/career-at-inviz-hiring.jpg"
                alt="Careers at Inviz AI - Hiring"
                layout="fullWidth"
                loading="lazy"
                placeholder="blurred"
              />
            </figure>
          )}
          <div className="text">
            <h2>
              <b>We are hiring</b>
            </h2>
            <div className="text-inner">
              <p>
                At Inviz, we believe technology is instrumental in bringing
                about a meaningful positive impact. We take up challenging
                projects and the impact we make is more powerful than before. We
                are looking to grow our team specializing in AI and ML to solve
                mission critical business problems.
              </p>
              {breakpoints.md && (
                <figure className="mobile">
                  <StaticImage
                    src="./../images/careers/career-at-inviz-hiring.jpg"
                    alt="Careers at Inviz AI - Hiring"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="blurred"
                  />
                </figure>
              )}
            </div>
          </div>
        </Container>
      </section>
      <section className="experience">
        <Container>
          <div className="row-1">
            <h3
              onClick={() => toggleExperience("fresher")}
              className={classNames(
                "toggle",
                experience === "fresher" && "active"
              )}
              data-exp="fresher"
            >
              <b>Early Career Professionals</b> <CaretDown />
            </h3>
            <SlideDown
              className={classNames(
                "my-dropdown-slidedown",
                experience === "fresher" && "active"
              )}
            >
              {experience === "fresher" && breakpoints.md && (
                <>{FresherContent}</>
              )}
            </SlideDown>

            <h3
              onClick={() => toggleExperience("senior")}
              className={classNames(
                "toggle",
                experience === "senior" && "active"
              )}
              data-exp="senior"
            >
              <b>Experienced Professionals</b> <CaretDown />
            </h3>
            <SlideDown
              className={classNames(
                "my-dropdown-slidedown",
                experience === "senior" && "active"
              )}
            >
              {experience === "senior" && breakpoints.md && (
                <>{SeniorContent}</>
              )}
            </SlideDown>
          </div>
          {!breakpoints.md && (
            <div className="rows-2">
              <SlideDown
                className={classNames("my-dropdown-slidedown", experience)}
              >
                {experience === "senior" ? SeniorContent : FresherContent}
              </SlideDown>
            </div>
          )}
        </Container>
      </section>
      <section className="values">
        <Container>
          <header>
            <h2>
              <b>Our Values</b>
            </h2>
            <p>
              We are driven by innovation. Innovation happens when there is a
              community of people who stand by each other, and work towards a
              unified vision. The only way we do this is by building a flat-
              organization in which we support and empowers each other in our
              journey.
            </p>
          </header>

          <div>
            <figure>
              <StaticImage
                src="./../images/careers/values.jpg"
                alt="Careers at Inviz AI - Hiring"
                layout="fullWidth"
                loading="lazy"
                placeholder="blurred"
              />
              <figcaption>
                <span>We empower industries to undergo AI transformation</span>
              </figcaption>
            </figure>
            <div>
              <p>
                Our social construct is based on constant collaboration and
                finding new ways to solve problems. Learning is at the crux of
                our growth process, and we encourage constant learning across
                domains. We believe in nurturing the spark of curiosity, and we
                are open to new ideas from everyone in our Inviz community.
              </p>
            </div>
          </div>
        </Container>
      </section>
      <section className="join-cta">
        <Container>
          <p>
            Join us and together, we can work towards building stronger
            businesses
          </p>
          <div className="cta">
            <BtnFilled
              to="https://careers.inviz.ai/jobs/Careers"
              color="blue"
              context="external"
            >
              See Open Positions
            </BtnFilled>
          </div>
        </Container>
      </section>
    </Page>
  );
};

export default CareerPage;
